import { Link } from "react-router-dom";
import CubicPartsLogo from "../CubicPartsLogo/CubicPartsLogo";
import styles from "./LogoContainer.module.scss";

export default function LogoContainer() {
  return (
    <Link to="/" className={styles.LogoContainer}>
      <CubicPartsLogo />
    </Link>
  );
}

import { ClientDetailsQuery, ClientsListQuery } from "dtos/Clients";
import {
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryClient,
} from "react-query";
import * as keys from "./queryKeys";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 2 * 60 * 1000,
    },
  },
});

export const InvalidateService = {
  clientDetails: (
    query?: ClientDetailsQuery,
    filters?: InvalidateQueryFilters,
    options?: InvalidateOptions
  ) =>
    queryClient.invalidateQueries(
      query ? [keys.CLIENT_DETAILS_KEY, query] : [keys.CLIENT_DETAILS_KEY],
      filters,
      options
    ),

  clientsList: (
    query?: ClientsListQuery,
    filters?: InvalidateQueryFilters,
    options?: InvalidateOptions
  ) =>
    queryClient.invalidateQueries(
      query ? [keys.CLIENTS_KEY, query] : [keys.CLIENTS_KEY],
      filters,
      options
    ),
};

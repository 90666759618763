import "./App.scss";
import ErrorBoundary from "./AppErrorBoundary";
import ThemeProvider from "./ThemeProvider";
import Routes from "./Routes";
import { SnackbarProvider } from "notistack";

import { BrowserRouter } from "react-router-dom";
import LogoContainer from "components/common/LogoContainer/LogoContainer";
import AppProvider from "./AppProvider";
import { SnackbarUtilsConfigurator } from "../../utils/SnackbarUtilsConfigurator";

function App() {
  return (
    <ErrorBoundary>
      <AppProvider>
        <BrowserRouter>
          <ThemeProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              maxSnack={4}
            >
              <SnackbarUtilsConfigurator />
              <LogoContainer />
              <div className="App">
                <Routes />
              </div>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </AppProvider>
    </ErrorBoundary>
  );
}

export default App;

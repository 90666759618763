import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./components/app/App";
import "./locale/i18n";
import { SENTRY_DSN, ENV } from "consts";

if (ENV === "stage" || ENV === "production")
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1,
    environment: ENV,
    ignoreErrors: ["Non-Error exception captured"],
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

import { ReactChild, ReactChildren } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";

import { queryClient } from "queries/queryClient";

interface IProps {
  children: ReactChildren | ReactChild;
}

function AppProvider({ children }: IProps) {
  return (
    <HelmetProvider>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2649319,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          },
        ]}
      >
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </HelmetProvider>
  );
}

export default AppProvider;

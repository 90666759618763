import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { withOnLoadTransition } from "hocs/withOnLoadTransition";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import animation from "./RouteAnimation.module.scss";
import styles from "./Routes.module.scss";
import { OrderStatusViewRoutes } from "components/pages/orderStatus/OrderStatusView.routes";

const Dashboard = React.lazy(
  () => import("components/pages/dashboard/Dashboard")
);
const Login = React.lazy(() => import("components/pages/login/Login"));

const ChangePassword = React.lazy(
  () => import("components/pages/changePassword/ChangePassword")
);

const ActivateAccount = React.lazy(
  () => import("components/pages/activate/Activate")
);

const RequestPasswordChange = React.lazy(
  () => import("components/pages/requestPasswordChange/RequestPasswordChange")
);

const OrderStatus = React.lazy(
  () => import("components/pages/orderStatus/OrderStatusView")
);

const ResendActivationLink = React.lazy(
  () => import("components/pages/resendActivationLink/ResendActivationLink")
);

const Error = () => (
  <div ref={null} className="animated-child">
    Route doesn't exist
  </div>
);

const MainRoutes = [
  {
    url: "/login",
    Component: withOnLoadTransition(Login),
  },
  {
    url: "/change-password/:token",
    Component: withOnLoadTransition(ChangePassword),
  },
  {
    url: "/activate/:token",
    Component: withOnLoadTransition(ActivateAccount),
  },
  {
    url: "/request-password-change",
    Component: withOnLoadTransition(RequestPasswordChange),
  },
  {
    url: "/dashboard",
    Component: withOnLoadTransition(Dashboard),
  },
  {
    url: OrderStatusViewRoutes.root,
    Component: withOnLoadTransition(OrderStatus),
  },
  {
    url: "/resend-activation-link",
    Component: withOnLoadTransition(ResendActivationLink),
  },
  {
    url: "/*",
    Component: Error,
  },
];
let Routes = () => {
  let location = useLocation();

  return (
    <TransitionGroup className={styles.RoutesWrapper}>
      <Switch location={location}>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        {MainRoutes.map(({ url, Component }) => (
          <Route path={url} key={`MAIN_ROUTE_${url}`}>
            {({ match }) => (
              <CSSTransition
                addEndListener={(node, done) =>
                  node.addEventListener("transitionend", done, false)
                }
                classNames={animation}
                in={!!match}
                timeout={{ exit: 0, enter: 600, appear: 600 }}
                mountOnEnter
              >
                <Component />
              </CSSTransition>
            )}
          </Route>
        ))}
      </Switch>
    </TransitionGroup>
  );
};

export default Routes;

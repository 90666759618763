import React, { memo, Suspense } from "react";
import Loader from "components/common/Loader/Loader";
// import animation from "./Animation.module.scss";

export const withOnLoadTransition = (
  Component: React.ExoticComponent<React.FunctionComponent<{}>>
) =>
  memo((props: any) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  ));

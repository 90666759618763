import { CircularProgress } from "@material-ui/core";

const Loader = ({
  size = "40px",
  color = "primary",
  wrapperStyles = {},
}: {
  size?: number | string;
  color?: "inherit" | "primary" | "secondary";
  wrapperStyles?: React.CSSProperties;
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...wrapperStyles,
      }}
    >
      <CircularProgress size={size} color={color} />
    </div>
  );
};

export default Loader;

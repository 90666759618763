import React from "react";
import * as Sentry from "@sentry/react";

const AppErrorBoundary = ({ children }: { children: React.ReactChild }) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("boundaryLevel", "AppErrorBoundary");
      }}
      fallback={<h1>Something went wrong. Please contact us:</h1>}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default AppErrorBoundary;

import {
  createTheme,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import type {} from "@material-ui/lab/themeAugmentation";

import colors from "styles/colors.module.scss";
const breakpoints = createBreakpoints({});

let theme = createTheme({
  palette: {
    common: {
      black: colors.cubicBlack,
      ...colors,
    },
  },
  breakpoints: breakpoints,
  typography: {
    h1: {},
    h2: {},
    h3: {
      fontSize: "4.8rem",
      fontWeight: 500,
      lineHeight: "5.6rem",
    },
    h4: {
      fontSize: "3.4rem",
      fontWeight: 500,
      lineHeight: "3.6rem",

      [breakpoints.down("sm")]: {
        fontSize: "2.4rem",
        fontWeight: 500,
        lineHeight: "3.2rem",
      },
    },
    h5: {
      fontSize: "2.4rem",
      fontWeight: 500,
      lineHeight: "2.4rem",
    },
    h6: {
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: "2.4rem",
    },
    subtitle1: {
      fontSize: "1.6rem",
      fontWeight: 500,
      lineHeight: "2.4rem",
    },
    subtitle2: {
      fontSize: "1.4rem",
      fontWeight: 500,
      lineHeight: "2.0rem",
    },
    body1: {
      fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: "2.4rem",
    },
    body2: {
      fontSize: "1.4rem",
      fontWeight: 400,
      lineHeight: "2rem",
    },
    caption: {
      fontSize: "1.2rem",
      lineHeight: "1.6rem",
      fontWeight: 500,
      color: colors.ciGray6,
    },
    overline: {
      color: colors.ciGray6,
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "1.6rem",
      marginBottom: "2px",
      marginTop: "6px",
      textTransform: "initial",
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        borderRadius: "2px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
      },
    },
    MuiButton: {
      root: {
        fontSize: "1.4rem",
        lineHeight: "1.6rem",
        fontWeight: 700,
      },
      outlinedSecondary: {
        color: colors.cubicRed,
      },
      startIcon: {
        marginRight: "15px",
      },
      text: {
        fontWeight: 700,
        color: colors.cubicBlue,
        fontSize: "1.4rem",
        lineHeight: "2rem",
        letterSpacing: 0.25,
        "&:hover": {
          background: "none",
          color: colors.ciSecondaryBlue3,
        },
      },
    },
    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          backgroundColor: colors.ciSecondaryBlue1,
        },
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "4px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: "2.4rem",
        height: "2.4rem",
      },
    },

    MuiAutocomplete: {
      endAdornment: {
        right: 10,
        top: "calc(50% - 16px)",
      },
      listbox: {
        padding: 0,
        maxHeight: "20vh",
      },
      option: {},
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: "12px 14px",
        },
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
          {
            padding: 0,
          },
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&:hover": {
          backgroundColor: "white",
        },
        "&$checked": {
          color: colors.cubicBlue,
          "&:hover": {
            backgroundColor: "white",
          },
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "1.3rem",
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 10,
      },

      notchedOutline: {
        "&:hover": {
          borderColor: colors.ciGray4,
        },
      },
      input: {
        padding: "12px 14px",
      },
      multiline: {
        padding: "13px 14px 11px",
        lineHeight: "20px",
      },
      root: {
        borderRadius: "2px",
        color: colors.cubicBlack,
        backgroundColor: "#F3F4F9",
        input: {
          fontWeight: 400,
          "&::placeholder": {
            color: "green",
          },
        },
        "&.Mui-disabled": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: colors.ciGray3,
          },
        },
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },

        "& fieldset": {
          borderColor: colors.ciGray2,
        },
        "&:hover": {
          borderColor: colors.ciGray4,

          "& fieldset": {
            borderColor: colors.ciGray3,
            borderWidth: "2px",
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.cubicBlue,
          },
        },
      },
    },
  },
});

interface ThemeProviderProps {}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;

export const resources = {
  en: {
    translation: {
      files: "file",
      files_plural: "files",
      personal_pickup: "personal pickup",
      packing_and_delivery_pl: "Packing and delivery",
      daysLeft: "One day left",
      daysLeft_plural: "{{count}} days left",
      daysAgo: "One day ago",
      daysAgo_plural: "{{count}} days ago",
      noDaysLeft: "Today",
      part_update: "{{extension}} file update",
      wrong_models: "wrong models",
      dashboardMenu: {
        Feedback: "Feedback",
      },
    },
  },
  pl: {
    translation: {
      dashboardMenu: {
        Feedback: "Oceny zamówień",
      },
      Date: "Data",
      "Validation errors": "Błędy walidacji",
      custom: "niestandardowy",
      Custom: "Niestandardowy",
      "Default address": "Domyślny adres",
      Country: "Państwo",
      "Address name": "Nazwa adresu",
      City: "Miasto",
      Address: "Adres",
      "Post code": "Kod pocztowy",
      "VAT number": "NIP",
      "Client details": "Dane klienta",
      "Log in": "Logowanie",
      Loading: "Ładowanie",
      Password: "Hasło",
      "Keep me logged in": "Nie wylogowuj mnie",
      "Reset here": "Zresetuj je tutaj",
      "Forgot your password?": "Nie pamiętasz hasła?",
      "Required field": "Pole wymagane",
      "Invalid value": "Nieprawidłowa wartość",
      "Invalid email format": "Nieprawidłowy email",
      Accepted: "Zatwierdzone",
      accepted: "zatwierdzone",
      Production: "W produkcji",
      production: "w produkcji",
      "Part state history": "Historia statusów części",
      "Part name": "Nazwa części",
      Ready: "Gotowe",
      ready: "gotowe",
      "All orders": "Wszystkie",
      Shipping: "Wysyłka",
      shipping: "wysyłka",
      Done: "Zakończone",
      done: "zakończone",
      finalized: "zakończone",
      "Select deadline": "Wybierz termin",
      "deadline today": "dzisiejszy termin",
      "Invalid email or password.": "Nieprawidłowy email lub hasło.",
      "Authentication failed, try again later.":
        "Błąd logowania, spróbuj ponownie później.",
      files_0: "plik",
      files_1: "pliki",
      files_2: "plików",
      daysLeft_0: "{{count}} dzień ważności",
      daysLeft_1: "{{count}} dni ważności",
      daysLeft_2: "Za {{count}} dni",
      daysAgo_0: "Wczoraj",
      daysAgo_1: "{{count}} dni temu",
      daysAgo_2: "{{count}} dni temu",
      noDaysLeft: "Dzisiaj",
      client: "Klient",
      worker: "Pracownik",
      Including: "W tym",
      "Add new part": "Dodaj nową część",
      "Create new order": "Stwórz nowe zamówienie",
      "General information": "Ogólne informacje",
      all: "wszystkie",
      "no interval": "brak przedziału",
      "after deadline": "po terminie",
      deadline: "termin",
      Deadline: "Termin",
      "3 days left": "3 dni ważności",
      "2 days left": "2 dni ważności",
      "New orders": "Nowe zamówienia",
      "Waybill ID": "Numer listu przewozowego",
      Download: "Pobierz",
      Preview: "Podgląd",
      Checklist: "Checklista",
      "Cancel waybill": "Anuluj list",
      Orders: "Zamówienia",
      Notifications: "Notyfikacje",
      Notification: "Notyfikacja",
      "Notification date": "Data przypomnienia",
      Clients: "Klienci",
      Access: "Dostępy",
      "Promo codes": "Kody rabatowe",
      Logout: "Wyloguj",
      Parts: "Części",
      "Change language": "Zmień język",
      Search: "Szukaj",
      "Search order": "Szukaj zamówienia",
      "Company name": "Nazwa firmy",
      Buyer: "Zamawiający",
      "File size": "Rozmiar pliku",
      "Number of parts": "Liczba części",
      "Material type": "Materiał",
      Price: "Cena",
      State: "Status",
      Commited: "Zgłoszone",
      Finalized: "Zakończone",
      "Waybill created at": "Data wygenerowania listu",
      "General informations": "Ogólne informacje",
      "First and last name": "Imię i nazwisko",
      "New order": "Nowe zamówienie",
      Folders: "Foldery",
      Move: "Przenieś",
      Name: "Nazwa",
      Share: "Udostępnij",
      Edit: "Edytuj",
      Delete: "Usuń",
      Page: "Strona",
      Invoice: "Faktura",
      "Invoice details": "Dane do faktury",
      "Invoice generation": "Generacja faktury",
      Complaint: "Reklamacja",
      Sample: "Próbka",
      "New promo code": "Nowy kod",
      "All clients": "Wszyscy klienci",
      Cancel: "Anuluj",
      "Destination folder": "Folder docelowy",
      "Delete promo code": "Usuń kod promocyjny",
      "Main deadline": "Główny termin",
      "Main address": "Główny adres",
      "Created at": "Data utworzenia",
      "Total price": "Cena całkowita",
      "Delivery address": "Adres dostawy",
      "Delivery address and price": "Adres i koszt dostawy",
      Person: "Osoba",
      "Personal pickup": "Odbiór osobisty",
      "Packing and delivery": "Pakowanie i dostawa",
      packing_and_delivery_pl: "Pakowanie i dostawa",
      "Select date from": "Wybierz datę od",
      "Select date to": "Wybierz datę do",
      "Select time period": "Wybierz przedział",
      "Deadline filters": "Filtry terminu",
      "Total delivery price": "Całkowita cena dostawy",
      "Default delivery address": "Domyślny adres dostawy",
      "Price excluding vat": "Cena bez vat",
      "Track order": "Śledź zamówienie",
      Feedback: "Oceń zamówienie",
      "Thank you for your feedback": "Dziękujemy za Twój feedback",
      "This will improve quality of our services":
        "Pozwoli nam to udoskonalić nasze usługi",
      "Pipedrive note": "Notatka z pipedrive",
      "Invoice delivery method": "Sposób wysyłki faktury",
      "Invoice delivery method has been changed successfully":
        "Zmieniono sposób wysyłki faktury pomyślnie",
      "Couldn't change invoice delivery method, please try again later":
        "Nie udało się zmienić sposobu wysyłki faktury, prosimy spróbować później",
      "VAT ID has not been found - check data":
        "NIP nie został odnaleziony - sprawdź dane",
      Parcel: "Paczka",
      package: "paczka",
      Waybills: "Listy przewozowe",
      "Parts list": "Lista części",
      "Orders list": "Lista zamówień",
      "Parts grid": "Siatka części",
      "Tracking number": "Numer listu",
      "Delivery service": "Firma przewozowa",
      Delivery: "Dostawa",
      "Go to order details view": "Idź do szczegółów zamówienia",
      "Track and feedback links": "Linki do śledzenia zamówienia i feedbacku",
      "Order state link": "Status zamówienia",
      "Order state": "Status zamówienia",
      "Feedback link": "Ocena zamówienia",
      "External order links": "Linki zewnętrzne zamówienia",
      Copy: "Kopiuj",
      "Part state": "Status części",
      "Rating is required": "Ocena jest wymagana",
      "Delivery type": "Typ dostawy",
      personal_pickup: "odbiór osobisty",
      "Last updated": "Ostatnio zaktualizowano",
      "Main delivery address": "Główny adres dostawy",
      Save: "Zapisz",
      Close: "Zamknij",
      Quantity: "Liczba kopii",
      "Open profile": "Otwórz profil",
      "Add delivery address": "Dodaj adres dostawy",
      "Add billing address": "Dodaj dane do faktury",
      "Copy and paste this identifier into 'Message' field in any part in AMFG new order form. Set parts status to 'pending'.":
        "Skopiuj ten identyfikator i wklej do pola “Message” przy którejkolwiek części podczas składania zamówienia w AMFG.",
      "CPID has been copied to clipboard": "CPID zostało skopiowane do schowka",
      "Couldn't copy CPID to your clipboard, please do it manually":
        "Nie udało się skopiować CPID do schowka, proszę zrobić to ręcznie",
      "Couldn't invalidate message, try again later":
        "Nie udało się unieważnić wiadomości, spróbuj ponownie później",
      "Cannot add part to new order if price is not defined.":
        "Nie można dodać części do nowego zamówienia bez zdefiniowanej ceny modelu.",
      "Invalidate message": "Unieważnij wiadomość",
      Cancelled: "Anulowane",
      cancelled: "anulowane",
      unknown: "nieznane",
      Unknown: "Nieznane",
      "Go to part details": "Pokaż szczegóły części",
      Override: "Nadpisz",
      "Add file": "Dodaj plik",
      "Date from": "Data od",
      "Date to": "Data do",
      "Reset form": "Zresetuj formularz",
      Postprocessing: "Postprodukcja",
      "PDF Documents": "Dokumenty PDF",
      part_update: "Update pliku {{extension}}",
      "Integration errors": "Błędy integracji",
      "Change notification date": "Zmień datę notyfikacji",
      "Manually enter new notification date.": "Wybierz nową datę notyfikacji.",
      Change: "Zmień",
      "Delete notification": "Usuń notyfikację",
      "This action requires confirmation.": "Ta akcja wymaga potwierdzenia.",
      "No more results": "Koniec wyników wyszukiwania",
      wrong_models: "Złe modele",
      "New order must contain at least one part":
        "Nowe zamówienie musi zawierać minimum jedną część",
      Reports: "Raporty",
      Monday: "Poniedziałek",
      Tuesday: "Wtorek",
      Wednesday: "Środa",
      Thursday: "Czwartek",
      Friday: "Piątek",
      Saturday: "Sobota",
      Income: "Przychód",
      "Income excluding VAT": "Przychód bez VAT",
      Sunday: "Niedziela",
      Finished: "Zakończone",
      Total: "Razem",
      "Quantity of part models": "Liczba modeli części",
      "Quantity of all model copies": "Liczba wszystkich kopii modeli",
      "Part models": "Modele części",
      Forecast: "Prognoza",
      Today: "Dzisiaj",
      Type: "Typ",
      "Order deadline from": "Termin realizacji zamówienia od",
      "Order deadline to": "Termin realizacji zamówienia do",
      Summary: "Podsumowanie",
      "Upload new file": "Dodaj nowy plik",
      Priority: "Priorytet",
      "Standard priority": "Standardowy",
      ASAP: "Szybka realizacja",
      Mon: "Pon",
      Tue: "Wt",
      Wed: "Śr",
      Thu: "Czw",
      Fri: "Pt",
      "PO number": "Numer PO",
      "Add folder content": "Dodaj zawartość folderu",
      "Lead time": "Data realizacji",
      "Lead time and price will be confirmed in offer":
        "Data realizacji i cena zostaną zatwierdzone w ofercie",
      Interval: "Interwał",
      "Order deadline": "Termin realizacji zamówienia",
      "Change deadline": "Zmień termin realizacji",
      "Order createdAt": "Termin utworzenia zamówienia",
      "Order created at": "Termin utworzenia zamówienia",
      Note: "Notatka",
      Postprocesses: "Postprocesy",
      Quote: "Wycena",
      "Order is waiting for quotation approval":
        "Zamówienie czeka na zatwierdzenie wyceny",
      Reject: "Odrzuć",
      "Accept quotation": "Zaakceptuj wycenę",
      Pending: "Oczekujące",
      "Data for AMFG": "Dane do AMFG",
      "Order summary .PDF": "Podsumowanie zamówienia .PDF",
      "Income excluding VAT - invoiced": "Przychód bez VAT - zafakturowany",
      "Income excluding VAT - production": "Przychód bez VAT - w produkcji",
      Day: "Dzień",
      Week: "Tydzień",
      Month: "Miesiąc",
      "Part size": "Wymiary części",
      "Part price": "Cena części",
      "Finish all postprocesses": "Zakończ wszystkie postprocesy",
      Finish: "Zakończ",
      "All postprocesses done": "Wszystkie postprocesy wykonane",
      "Create quote": "Stwórz wycenę",
      "Upload new document": "Dodaj nowy dokument",
      "Quote was rejected on": "Wycena została odrzucona w dniu",
      "Quote was accepted on": "Wycena została zaakceptowana w dniu",
      "Unit price excluding vat": "Cena jednego modelu bez vat",
      "Item total price excluding vat": "Całkowita cena części bez vat",
      "Order price summary including VAT (23%)":
        "Cena całego zamówienia z VAT (23%)",
      "Show finalized orders": "Pokaż zakończone zamówienia",
      "Phone number": "Numer telefonu",
      "Completed parts": "Zakończone powodzeniem",
      "Failed parts": "Zakończone niepowodzeniem",
      "Download QR code": "Pobierz kod QR",
    },
  },
};
